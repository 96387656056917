import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import SchedulePage from './components/SchedulePage';
import SpeakersPage from './components/SpeakersPage';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/schedule" element={<SchedulePage />} />
      <Route path="/speakers" element={<SpeakersPage />} />
    </Routes>
  </Router>
);

export default App;

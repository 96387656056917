import { FaCalendarAlt, FaChalkboardTeacher } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AnimatedDigit = ({ value, label }) => {
  return (
    <div className="flex flex-col items-center p-4 bg-white bg-opacity-70 rounded-lg shadow-md">
      <div className="relative h-12 w-12 mb-2 overflow-hidden">
        <div
          key={value}
          className="absolute w-full h-full flex justify-center items-center text-3xl font-bold text-blue-600 rounded animate-flip-in"
        >
          {value}
        </div>
      </div>
      <span className="text-sm text-gray-500 capitalize">{label}</span>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const timerComponents = Object.entries(timeLeft).map(([interval, value]) => (
    <AnimatedDigit key={`${interval}-${value}`} value={String(value).padStart(2, '0')} label={interval} />
  ));

  return (
    <div className="flex justify-center space-x-4 my-8">
      {timerComponents.length ? timerComponents : <span>The event has started!</span>}
    </div>
  );
};

const TappableButton = ({ icon: Icon, label, onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <button 
      className={`flex flex-col justify-center items-center w-48 h-24 bg-white bg-opacity-70 rounded-lg shadow-md transition-all duration-150 ${isPressed ? 'transform scale-95 bg-gray-100' : 'hover:bg-gray-50'}`}
      onTouchStart={() => setIsPressed(true)}
      onTouchEnd={() => setIsPressed(false)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onMouseLeave={() => setIsPressed(false)}
      onClick={onClick}
    >
      <Icon className={`text-4xl ${isPressed ? 'text-blue-700' : 'text-gray-800'} transition-colors duration-150`} />
      <p className={`mt-2 text-lg ${isPressed ? 'text-blue-900' : 'text-gray-800'} transition-colors duration-150`}>{label}</p>
    </button>
  );
};

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center relative">
      {/* Background gradient */}
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `
            linear-gradient(to bottom right, rgba(96, 165, 250, 0.9), rgba(147, 197, 253, 0.9), rgba(153, 246, 228, 0.9))
          `,
        }}
      ></div>
      
      {/* Background image with adjustable opacity */}
      <div 
        className="absolute inset-0 z-10 bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('/valuex_logo_2_rotated.png')`,
          backgroundSize: '400px',
          opacity: 0.4, // Adjust this value to change the image opacity
        }}
      ></div>
      
      {/* Content */}
      <div className="relative z-20 flex flex-col min-h-screen">
        {/* Logo and Header */}
        <header className="w-full text-center pb-4 shadow-sm relative overflow-hidden">
          <div className="relative flex flex-col items-center z-10 pt-12">
            <div className="inline-block">
              <h1 className="text-4xl text-gray-800 font-bold tracking-wide">ValueX ME 2024</h1>
              <hr className="border-t-2 border-gray-800 opacity-75 mt-2" />
            </div>
            <p className="text-gray-700 text-lg mt-1 font-medium">November 7-9, 2024</p>
          </div>
        </header>

        {/* Countdown Timer */}
        <div className='mt-20'>
          <CountdownTimer targetDate="2024-11-07T08:30:00" />
        </div>

        {/* Schedule and Speakers Buttons */}
        <div className="flex flex-col items-center gap-5 mt-16 space-y-8">
          <TappableButton 
            icon={FaCalendarAlt} 
            label="Schedule" 
            onClick={() => navigate('/schedule')}
          />
          <TappableButton 
            icon={FaChalkboardTeacher} 
            label="Speakers" 
            onClick={() => navigate('/speakers')}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
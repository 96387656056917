import React, { useState, useEffect, useRef } from 'react';
import { FaChevronLeft, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { IoHomeSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const EventCard = ({ time, title, venue, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const titleRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (titleRef.current && containerRef.current) {
      const titleWidth = titleRef.current.scrollWidth;
      const containerWidth = containerRef.current.clientWidth;
      setIsTruncated(titleWidth > containerWidth);
    }
  }, [title]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.05 }}
      className="bg-white rounded-lg shadow-sm p-3 mb-2 transition-all duration-300 hover:shadow-md hover:bg-opacity-90"
    >
      <div className="flex items-center">
        <div className="w-20 flex-shrink-0">
          <p className="text-xs font-semibold text-gray-600">{time}</p>
        </div>
        <div className="flex-grow overflow-hidden" ref={containerRef}>
          <div className="flex items-center justify-between">
            <div className={`flex-grow overflow-hidden ${isExpanded ? '' : 'h-6'}`}>
              <h3 
                ref={titleRef}
                className={`text-sm font-semibold text-gray-800 ${isExpanded ? 'whitespace-normal' : 'truncate'}`}
              >
                {title}
              </h3>
            </div>
            {isTruncated && (
              <button 
                onClick={() => setIsExpanded(!isExpanded)}
                className="ml-2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none flex-shrink-0"
              >
                <FaChevronDown 
                  className={`w-4 h-4 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                />
              </button>
            )}
          </div>
          {venue && <p className="text-xs text-gray-600 truncate">{venue}</p>}
        </div>
      </div>
    </motion.div>
  );
};
  

const SchedulePage = () => {
  const [currentDay, setCurrentDay] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const schedule = {
    1: [
      { time: '08:30 AM', title: 'Meet & Greet', venue: 'Studio M Meeting Room Foyer'},
      { time: '09:00 AM 10:30 AM', title: 'Session 1', venue: 'Studio M Meeting Room'},
      { time: '10:30 AM 10:50 AM', title: 'Tea/Coffee & Snacks Break', venue: 'Studio M Meeting Room Foyer'},
      { time: '10:50 AM 12:30 PM', title: 'Session 2', venue: 'Studio M Meeting Room'},
      { time: '12:30 PM 01:50 PM', title: 'Lunch', venue: 'Hotel Restaurant - Creek Kitchen'},
      { time: '01:50 PM 03:30 PM', title: 'Session 3', venue: 'Studio M Meeting Room'},
      { time: '03:30 AM 03:50 AM', title: 'Tea/Coffee & Snacks Break', venue: 'Studio M Meeting Room Foyer'},
      { time: '03:50 PM 05:30 PM', title: 'Session 4', venue: 'Studio M Meeting Room'},
      { time: '05:30 PM 07:00 PM', title: 'Free Time', venue: ''},
      { time: '07:00 PM 09:30 PM', title: 'Dinner', venue: 'Studio M Terrace'}
    ],
    2: [
      { time: '08:45 AM', title: 'Tea/Coffee', venue: 'Studio M Meeting Room Foyer'},
      { time: '09:00 AM 10:30 AM', title: 'Session 5', venue: 'Studio M Meeting Room'},
      { time: '10:30 AM 10:50 AM', title: 'Tea/Coffee & Snacks Break', venue: 'Studio M Meeting Room Foyer'},
      { time: '10:50 AM 11:20 AM', title: 'Fireside chat: "Energy Transition - whereto from Here" - Bryan Lawrence and Mark Brownstein', venue: 'Studio M Meeting Room'},
      { time: '11:20 AM 12:30 PM', title: 'Session 6', venue: 'Studio M Meeting Room'},
      { time: '12:30 PM 01:50 PM', title: 'Lunch', venue: 'Hotel Restaurant - Creek Kitchen'},
      { time: '01:50 PM 03:30 PM', title: 'Session 7', venue: 'Studio M Meeting Room'},
      { time: '03:30 AM 03:50 AM', title: 'Tea/Coffee & Snacks Break', venue: 'Studio M Meeting Room Foyer'},
      { time: '03:50 PM 05:30 PM', title: 'Session 8', venue: 'Studio M Meeting Room'},
      { time: '05:30 PM 06:50 PM', title: 'Free Time', venue: ''},
      { time: '06:50 PM', title: 'Assemble at the Marriott lobby to walk along the promenade for dinner', venue: 'Marriott Marquis, Jewel of the Creek'},
      { time: '07:00 PM 09:30 PM', title: 'Dinner', venue: 'Serein Restaurant, Hilton Creek Hotel'}
    ],
    3: [
      { time: '08:45 AM', title: 'Tea/Coffee', venue: 'Studio M Meeting Room Foyer'},
      { time: '09:00 AM 10:40 AM', title: 'Session 9', venue: 'Studio M Meeting Room'},
      { time: '10:40 AM 11:00 AM', title: 'Tea/Coffee & Snacks Break', venue: 'Studio M Meeting Room Foyer'},
      { time: '11:00 AM 12:30 PM', title: 'Panel discussion on OpenAI by Stig Brodersen with panelists David Diranko and Jakob Kucharczyk', venue: 'Studio M Meeting Room'},
      { time: '12:30 PM 02:00 PM', title: 'Lunch', venue: 'Hotel Restaurant - Creek Kitchen'},
      { time: '02:00 PM 07:00 PM', title: 'Afernoon at leisure - free time', venue: ''},
      { time: '07:00 PM 10:00 PM', title: 'Dinner', venue: 'Studio M Terrace'}
    ],
  };

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [currentDay]);

  const changeDay = (increment) => {
    setCurrentDay((prevDay) => {
      const newDay = prevDay + increment;
      return newDay < 1 ? 3 : newDay > 3 ? 1 : newDay;
    });
  };

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center relative">
        {/* Background gradient */}
        <div 
            className="fixed inset-0 z-0"
            style={{
                backgroundImage: `
                linear-gradient(to bottom right, rgba(96, 165, 250, 0.9), rgba(147, 197, 253, 0.9), rgba(153, 246, 228, 0.9))
                `,
            }}
        ></div>

        {/* Background image with adjustable opacity */}
        <div 
            className="fixed inset-0 z-10 bg-center bg-no-repeat pointer-events-none"
            style={{
                backgroundImage: `url('/valuex_logo_2_rotated.png')`,
                backgroundSize: '400px',
                opacity: 0.4,
            }}
        ></div>
      {/* Content */}
      <div className="relative z-20 flex flex-col h-screen">
        {/* Fixed header section */}
        <div className="fixed top-0 left-0 right-0 backdrop-blur-md bg-white/30 shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={goToHomepage}
              className="bg-white bg-opacity-70 text-black text-sm font-bold py-2 px-4 rounded-full flex items-center"
            >
              <IoHomeSharp />
            </button>
          </div>
          <h1 className="text-3xl font-bold text-center mb-8">Event Schedule</h1>
          
          {/* Day selector */}
          <div className="flex justify-between items-center">
            <button onClick={() => changeDay(-1)} className="text-2xl text-gray-800 focus:outline-none">
              <FaChevronLeft />
            </button>
            <h2 className="text-2xl font-semibold">Day {currentDay}</h2>
            <button onClick={() => changeDay(1)} className="text-2xl text-gray-800 focus:outline-none">
              <FaChevronRight />
            </button>
          </div>
        </div>

        {/* Scrollable content with padding to account for fixed header */}
        <div className="flex-grow overflow-y-auto pt-52 px-6 pb-6">
          <div className="space-y-4">
            <AnimatePresence>
              {!isLoading && schedule[currentDay].map((event, index) => (
                <EventCard key={`${currentDay}-${index}`} {...event} index={index} />
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulePage;
import React from 'react';
import { motion } from 'framer-motion';

const SpeakerTile = ({ name, imageLink, category, onViewBio }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="relative bg-gradient-to-br from-white via-blue-50 to-teal-50 rounded-xl shadow-lg overflow-hidden flex flex-col items-center p-6 transition duration-300 hover:shadow-xl"
  >
    <div className="w-28 h-28 mb-4 relative">
      <img 
        src={imageLink} 
        alt={name} 
        className="w-full h-full object-cover rounded-full border-4 border-white shadow-md"
      />
      <div className="absolute inset-0 rounded-full shadow-inner"></div>
    </div>
    <h3 className="text-lg font-semibold mb-1 text-center text-gray-800">{name}</h3>
    <p className="text-sm text-gray-600 mb-4">{category}</p>
    <button
      onClick={onViewBio}
      className="w-full bg-gradient-to-r from-blue-500 to-teal-400 text-white py-2 px-4 rounded-full text-sm font-medium hover:from-blue-600 hover:to-teal-500 transition duration-300 shadow-md"
    >
      View Bio
    </button>
  </motion.div>
);

export default SpeakerTile;
import React, { useState, useEffect } from 'react';
import { IoHomeSharp, IoSearch, IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import db from '../firebase.js';
import { collection, getDocs } from 'firebase/firestore/lite';
import Modal from './BioModal'; // Import the new Modal component
import SpeakerTile from './SpeakerTile.js';


const SpeakersPage = () => {
    const [speakers, setSpeakers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSpeakers, setFilteredSpeakers] = useState([]);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    //fetch attendee names, bios and imageLink from Firestore
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching attendee names and bios from Firestore...");
                const attendeeCol = collection(db, 'attendees');
                const attendeeSnapshot = await getDocs(attendeeCol);
                if (attendeeSnapshot.empty) {
                    console.warn("No attendees found in Firestore!");
                } else {
                    console.log("Attendees found:", attendeeSnapshot.size);
                }

                const fetchedSpeakers = attendeeSnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name,
                    imageLink: doc.data().imageLink,
                    category: "Speaker", 
                    bio: doc.data().bio
                }));

                // Sort speakers alphabetically by name
                const sortedSpeakers = fetchedSpeakers.sort((a, b) => 
                    a.name.localeCompare(b.name)
                );
                
                console.log("Fetched Speakers:", sortedSpeakers);
                
                setSpeakers(sortedSpeakers);
                setFilteredSpeakers(sortedSpeakers);
            } catch (error) {
                console.error("Error fetching data from Firestore:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredSpeakers(speakers);
        } else {
            const filtered = speakers.filter(speaker => 
                speaker.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredSpeakers(filtered);
        }
    }, [searchTerm, speakers]);

    useEffect(() => {
        const body = document.body;
        if (isModalOpen) {
            body.classList.add('modal-open');
            // Store the current scroll position
            body.style.top = `-${window.scrollY}px`;
        } else {
            body.classList.remove('modal-open');
            // Restore the scroll position
            const scrollY = body.style.top;
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }

        return () => {
            body.classList.remove('modal-open');
            body.style.top = '';
        };
    }, [isModalOpen]);

    const goToHomepage = () => {
        navigate('/');
    };

    const handleViewBio = (speakerId) => {
        const speaker = speakers.find(s => s.id === speakerId);
        setSelectedSpeaker(speaker);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSpeaker(null);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    return (
        <div className="min-h-screen flex flex-col bg-cover bg-center relative">
            {/* Background gradient */}
            <div 
                className="fixed inset-0 z-0"
                style={{
                    backgroundImage: `
                    linear-gradient(to bottom right, rgba(96, 165, 250, 0.9), rgba(147, 197, 253, 0.9), rgba(153, 246, 228, 0.9))
                    `,
                }}
            ></div>

            {/* Background image with adjustable opacity */}
            <div 
                className="fixed inset-0 z-10 bg-center bg-no-repeat pointer-events-none"
                style={{
                    backgroundImage: `url('/valuex_logo_2_rotated.png')`,
                    backgroundSize: '400px',
                    opacity: 0.4,
                }}
            ></div>

            {/* Fixed Header with backdrop blur and subtle background */}
            <div className="fixed top-0 left-0 right-0 z-30 backdrop-blur-md bg-white/30 p-4 shadow-sm">
                <div className="flex justify-between items-center mb-4">
                    <button
                        onClick={goToHomepage}
                        className="bg-white bg-opacity-70 text-black text-sm font-bold py-2 px-4 rounded-full flex items-center"
                    >
                        <IoHomeSharp />
                    </button>
                </div>

                <h1 className="text-3xl font-bold text-center mb-8">Speakers</h1>

                {/* Search bar */}
                <div className="mb-6 relative">
                    <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type="text"
                        placeholder="Search speakers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-10 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {searchTerm && (
                        <button
                            onClick={handleClearSearch}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        >
                            <IoClose />
                        </button>
                    )}
                </div>
            </div>

            {/* Scrollable Content */}
            <div className="relative z-20 flex flex-col flex-grow mt-56 px-4">
                {/* Speakers grid */}
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    <AnimatePresence>
                        {filteredSpeakers.map((speaker) => (
                            <SpeakerTile
                                key={speaker.id}
                                {...speaker}
                                onViewBio={() => handleViewBio(speaker.id)}
                            />
                        ))}
                    </AnimatePresence>
                </div>
            </div>

            {/* Bio Modal */}
            <Modal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal}
                title={selectedSpeaker?.name || 'Speaker Bio'}
            >
                {selectedSpeaker && (
                    <div>
                        <p className="text-base">{selectedSpeaker.bio}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default SpeakersPage;
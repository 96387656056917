import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyCHBumW5y2IzChyoVmpFPcN-KISlqTwGWU",
  authDomain: "valuex-pwa-attendee-data.firebaseapp.com",
  projectId: "valuex-pwa-attendee-data",
  storageBucket: "valuex-pwa-attendee-data.appspot.com",
  messagingSenderId: "640660742506",
  appId: "1:640660742506:web:27512c03baf8294a240513",
  measurementId: "G-1CZ2XV05H2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the Firestore database
const db = getFirestore(app); 

export default db;
